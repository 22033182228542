import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.scss";
import './asserts/css/main.0729152f.css'
import './asserts/fonts/fonts.css'

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
