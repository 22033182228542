import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import Loading from "./components/Loading";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { ToastContainer } from "react-toastify";
import Routes from "./routes";


function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes />
        <ToastContainer className="!z-[99999]" />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
